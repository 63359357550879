const LocalStorage = global.localStorage ? localStorage : null;
const SessionStorage = global.sessionStorage ? sessionStorage : null;

const AUTH_TOKEN = "aTkn";
const AUTH_REFRESH_TOKEN = "aRTkn";

const getToken = (key: string) => {
  const localAuth = LocalStorage?.getItem(key);

  if (localAuth && localAuth !== "undefined") {
    return localAuth;
  }

  const sessionAuth = SessionStorage?.getItem(key);

  if (sessionAuth && sessionAuth !== "undefined") {
    return sessionAuth;
  }

  return "";
};

const isSaveInSession = () => {
  const sessionAuth = SessionStorage?.getItem(AUTH_TOKEN);

  if (sessionAuth && sessionAuth !== "undefined") {
    return true;
  }

  return false;
};

const setToken = (key: string, value: string) => {
  try {
    if (isSaveInSession()) {
      SessionStorage?.setItem(key, value);
    } else {
      LocalStorage?.setItem(key, value);
    }
  } catch (err) {
    console.error("setToken", err, key, value);
  }
};

const getTokenAuth = () => getToken(AUTH_TOKEN);
const setTokenAuth = (val: string) => {
  setToken(AUTH_TOKEN, val);
};

const getRefreshToken = () => getToken(AUTH_REFRESH_TOKEN);

const getTokenAnonymous = () => getToken("anonymous");
const saveAnonymousToken = (value: string) => setToken("anonymous", value);

const getBearerToken = () => {
  const token = getTokenAuth();
  return token ? `Bearer ${token}` : "";
};

const erase = () => {
  LocalStorage?.removeItem(AUTH_TOKEN);
  LocalStorage?.removeItem(AUTH_REFRESH_TOKEN);
  SessionStorage?.removeItem(AUTH_TOKEN);
  SessionStorage?.removeItem(AUTH_REFRESH_TOKEN);
};

const eraseAuthOnly = () => {
  LocalStorage?.removeItem(AUTH_TOKEN);
  SessionStorage?.removeItem(AUTH_TOKEN);
};

const set = (
  { token, tokenRefresh }: { token: string; tokenRefresh?: string },
  remember?: boolean,
) => {
  erase();

  if (remember) {
    token && LocalStorage?.setItem(AUTH_TOKEN, token);
    tokenRefresh && LocalStorage?.setItem(AUTH_REFRESH_TOKEN, tokenRefresh);
  } else {
    token && SessionStorage?.setItem(AUTH_TOKEN, token);
    tokenRefresh && SessionStorage?.setItem(AUTH_REFRESH_TOKEN, tokenRefresh);
  }
};

const tokenStorage = {
  isSaveInSession,
  getTokenAuth,
  setTokenAuth,
  getRefreshToken,
  getTokenAnonymous,
  saveAnonymousToken,
  getBearerToken,
  set,
  erase,
  eraseAuthOnly,
};

export default tokenStorage;
