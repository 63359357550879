import Spinner from "react-bootstrap/Spinner";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface SpinnerOverlay {
  notTransparent?: boolean;
}

const SpinnerOverlay = ({ notTransparent = false }: SpinnerOverlay) => {
  return (
    <div className={styles.spinnerOverlay}>
      <div
        className={classNames(
          styles.content,
          notTransparent && styles.notTransparent,
        )}
      >
        <Spinner className={styles.loader} variant="secondary" />
      </div>
    </div>
  );
};

export default SpinnerOverlay;
