// @ts-nocheck
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import tokenStorage from "@/utils/token";

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      responseType?: AxiosRequestConfig["responseType"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers, responseType }) => {
    const requestHeaders = headers ? { ...headers } : {};
    if (!requestHeaders["Content-Type"]) {
      requestHeaders["Content-Type"] = "application/json";
    }
    const token = tokenStorage.getBearerToken();
    if (token) {
      // @ts-ignore
      requestHeaders.Authorization = token;
    }

    let baseUrl =
      typeof window !== "undefined" && window.location.origin
        ? window.location.origin
        : "";
    baseUrl =
      baseUrl === "http://127.0.0.1:3000"
        ? "https://juliatestcompany.sasaprofit-dev.syntech.info"
        : baseUrl;
    baseUrl = `${baseUrl}/api/v1`;

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: requestHeaders,
        responseType,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (
        err?.response?.status === 401 &&
        err?.response?.statusText === "Unauthorized"
      ) {
        // tokenStorage.erase();
        //TODO: think what to do
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

interface AxiosParams {
  url: string;
  method?: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
  skipAuth?: boolean;
  responseType?: AxiosRequestConfig["responseType"];
  isSSR?: boolean;
  noCache?: boolean;
}

export const axiosQuery = async ({
  url,
  method,
  data,
  params,
  headers,
  skipAuth = false,
  responseType,
  isSSR = false,
  noCache = false,
}: AxiosParams) => {
  const requestHeaders = headers ? { ...headers } : {};
  if (!requestHeaders["Content-Type"]) {
    requestHeaders["Content-Type"] = "application/json";
  }
  if (noCache) {
    requestHeaders["Cache-Control"] = "no-store";
  }
  if (!isSSR) {
    const token = tokenStorage.getBearerToken();
    if (token && !skipAuth) {
      // @ts-ignore
      requestHeaders.Authorization = token;
    }
  }

  let baseUrl =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  if (isSSR) {
    baseUrl = process.env.NEXT_PUBLIC_APP_API;
  } else {
    baseUrl =
      baseUrl === "http://127.0.0.1:3000"
        ? "https://juliatestcompany.sasaprofit-dev.syntech.info"
        : baseUrl;
    baseUrl = `${baseUrl}/api/v1`;
  }

  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      data,
      params,
      headers: requestHeaders,
      responseType,
    });
    return result;
  } catch (err) {
    throw err;
  }
};
