"use client";
import { ToastContainer as Container } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const ToastContainer = () => (
  <Container
    icon={false}
    closeButton={false}
    position="top-right"
    autoClose={2000}
    hideProgressBar={true}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    pauseOnHover
  />
);

export default ToastContainer;
