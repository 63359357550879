"use client";

import { useEffect, useState } from "react";
import { axiosQuery } from "@/utils/axios";
import apiPath from "@/constants/api/apiPath";
import SpinnerOverlay from "@/components/SpinnerOverlay/SpinnerOverlay";
import { isPublicDomain } from "@/utils/url";

const ThemeLoader = () => {
  const [themeLoaded, setThemeLoaded] = useState(false);
  useEffect(() => {
    if (isPublicDomain()) {
      setThemeLoaded(true);
      return;
    }
    axiosQuery({
      url: apiPath.getCompany(),
      method: "GET",
      data: {},
    })
      .then((result) => {
        const themeName = result?.data?.theme?.name || "blue";
        document?.documentElement?.setAttribute("data-bs-theme", themeName);
        setThemeLoaded(true);
      })
      .catch((error) => {
        setThemeLoaded(true);
      });
  }, []);
  if (themeLoaded) return null;
  return <SpinnerOverlay notTransparent />;
};

export default ThemeLoader;
