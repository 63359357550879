const API_AUTHENTICATION = `/authentication/`;
const API_AUTHENTICATION_PASSWORD = `/authentication/password/`;
const API_AUTHENTICATION_TOKEN = `/authentication/token/`;
const API_USERS = `/users/`;
const API_STAGES = `/stages/`;
const API_PROJECTS = `/projects/`;
const API_IDEAS = `/ideas/`;
const API_COMPANIES = `/companies/`;
const API_DASHBOARD = `/dashboard/`;
const API_REPORT = `/report/`;
const API_NOTIFICATIONS = `/notifications/`;

const apiPath = {
  getCompany: () => `${API_COMPANIES}public/`,
  getSettings: () => `/public/settings/`,

  signIn: () => `${API_AUTHENTICATION}login/`,
  logout: () => `${API_AUTHENTICATION}logout/`,

  contact: () => `/public/contact-us/`,

  getMe: () => `${API_AUTHENTICATION}user/`,
  updateMe: () => `${API_AUTHENTICATION}user/`,

  changePassword: () => `${API_AUTHENTICATION_PASSWORD}change/`,
  resetPassword: () => `${API_AUTHENTICATION_PASSWORD}reset/`,
  resetConfirmPassword: () => `${API_AUTHENTICATION_PASSWORD}reset/confirm/`,

  tokenRefresh: () => `${API_AUTHENTICATION_TOKEN}refresh/`,
  tokenVerify: () => `${API_AUTHENTICATION_TOKEN}verify/`,

  verify2FA: () => `${API_AUTHENTICATION}verify-2fa/`,

  choices: () => `${API_USERS}choices/`,
  changeEmail: () => `${API_USERS}change-email/`,
  changeEmailConfirm: () => `${API_USERS}change-email/confirm/`,

  stages: () => `${API_STAGES}`,

  projectsChoices: () => `${API_PROJECTS}choices/`,
  project: () => `${API_PROJECTS}`,
  projectReview: (id: string | number) => `${API_PROJECTS}${id}/review/`,
  projectValidateStep: () => `${API_PROJECTS}validate-step/`,
  projectValidateStepById: (id: string | number) =>
    `${API_PROJECTS}${id}/validate-step/`,
  projectsExport: () => `${API_PROJECTS}export`,
  projectsForReport: () => `${API_PROJECTS}report/`,

  idea: () => `${API_IDEAS}`,
  ideaTransfer: (id: string | number) => `${API_IDEAS}${id}/transfer/`,
  ideaValidateStep: () => `${API_IDEAS}validate-step/`,
  ideaTransferValidateStep: () => `${API_IDEAS}transfer/validate-step/`,

  dashboard: () => `${API_DASHBOARD}`,
  report: () => `${API_REPORT}`,

  notifications: () => `${API_NOTIFICATIONS}`,
};

export default apiPath;
